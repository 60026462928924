import React, { Component } from "react"
import Utils from "../../utils/node.utils"
import { store as tStore } from "../Store/Store"
import { Link, navigate } from "gatsby"

export default class WishlistView extends Component {
  state = {
    cart: [],
    products: [],
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.componentCleanup)
    const cart = Utils.GetCart("wishlist-items")

    if (!cart.length || cart.length === 0) {
      return
    }

    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.storeUpdate()
    this.setState({
      cart: cart,
    })
  }

  componentWillUnmount() {
    this.componentCleanup()
  }

  componentCleanup = () => {
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const storeState = tStore.getState()

    if (storeState.t) {
      this.setState(
        {
          t: storeState.t,
        },
        this.loadInitialData
      )
    }
  }

  loadInitialData = () => {
    const { productsLoaded, t } = this.state

    if (!t) {
      console.warn("Cannot init, not authenticated")
      return
    }

    if (!productsLoaded) {
      this.fetchProducts()
      this.setState({
        productsLoaded: true,
      })
    }
  }

  handleAPIError = err => {
    if (err.response && err.response.status == 404) {
      localStorage.removeItem("wishlist-items")
      this.setState({
        cart: [],
      })
    }
  }

  fetchProducts = () => {
    const { language } = this.props
    const { cart, t } = this.state
    if (!t) {
      console.warn("Cannot fetch products, not authenticated")
      return
    }
    this.productCancelToken = Utils.GetProducts(
      Utils.CartToCheck(cart),
      language,
      t,
      this.setProducts,
      this.handleAPIError
    )
  }

  setProducts = checkResult => {
    const { language } = this.props
    const locale = Utils.LanguageToLocale(language)

    if (!checkResult.products || checkResult.products.length === 0) {
      this.setState({
        products: [],
      })
      return
    }

    this.setState({
      products: checkResult.products.map(item => {
        return {
          id: item.id,
          slug: item.slug,
          name: Utils.GetContentKeyValue(item.content, "name", locale),
          thumbnail: Utils.GetContentKeyValue(item.content, "thumbnails"),
        }
      }),
    })
  }

  createThumbnail = (item, link) => {
    const { noLinks } = this.props

    if (noLinks) {
      return (
        <img
          src={item.thumbnail}
          alt={item.name}
          title={item.name}
          className="img-fluid"
        />
      )
    }
    return (
      <Link to={link} title={item.name}>
        <img
          src={item.thumbnail}
          alt={item.name}
          title={item.name}
          className="img-fluid"
        />
      </Link>
    )
  }

  createName = (item, link) => {
    const { noLinks } = this.props

    if (noLinks) {
      return item.name + " " + item.optionString
    }
    return (
      <Link
        to={link}
        title={item.name + " " + item.optionString}
        className="cart-link"
      >
        {item.name} {item.optionString}
      </Link>
    )
  }

  onUpdateCart = cart => {
    if (cart) {
      this.setState(
        {
          cart: cart,
        },
        this.afterUpdateCart
      )
    }
  }

  afterUpdateCart = () => {
    this.fetchProducts()
  }

  onRemove = id => {
    const { commonContentMap } = this.props

    Utils.RemoveFromCart(
      { id: id },
      undefined,
      "wishlist-items",
      true,
      commonContentMap,
      this.onUpdateCart
    )
  }

  getRemoveButton = item => {
    const { commonContentMap } = this.props

    return (
      <div
        title={commonContentMap.button_remove.value}
        className="btn btn-dark mt-1 mt-lg-0 col-12 col-lg-1"
        onClick={() => {
          this.onRemove(item.id)
        }}
      >
        <div className="d-none d-lg-block">
          <i
            className={"fa fa-minus"}
            alt={commonContentMap.button_remove.value}
          />
        </div>
        <div className="d-lg-none d-block">
          {commonContentMap.button_remove.value}
        </div>
      </div>
    )
  }

  createProductRows = () => {
    const { language } = this.props
    const { products } = this.state

    return products.map((item, i) => {
      const link = Utils.GetLanguageLink(language, "/shop/" + item.slug)
      return (
        <div className="row cart-item align-items-center" key={i}>
          <div className="col-12 col-md-3 col-lg-1">
            {this.createThumbnail(item, link)}
          </div>
          <div className="col-12 col-md-9 col-lg-10 text-md text-center text-md-left">
            {this.createName(item, link)}
          </div>
          {this.getRemoveButton(item)}
        </div>
      )
    })
  }

  render() {
    const { contentMap } = this.props
    const { cart } = this.state

    if (cart.length === 0) {
      return (
        <div className="container mb-5 cart-empty justify-content-center">
          {contentMap.empty.value}
        </div>
      )
    }
    return (
      <div className="container text-uppercase">{this.createProductRows()}</div>
    )
  }
}
